import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import TransactionTable from "./TransactionTable";

class ProductHome extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div>
                <TransactionTable />
                {/*<TransactionBottomSpan />*/}
            </div>
        );
    }
}

export default ProductHome;