import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SupplierService from "../../services/SupplierService";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {Alert, Button, Container} from "react-bootstrap";
import SupplierEditModal from "./SupplierEditModal";

class SupplierTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            suppliers: [],
            columns: [
                {
                    dataField: 'code',
                    text: 'Supplier Code',
                    filter: textFilter(), // apply text filter
                    sort: true
                },
                {
                    dataField: 'name',
                    text: 'Supplier Name',
                    filter: textFilter(), // apply text filter
                    sort: true
                },
                {
                    dataField: 'addressStr',
                    text: 'Address',
                    filter: textFilter(), // apply text filter
                },
                {
                    dataField: '',
                    text: 'Contact Info',
                },
            ],
            expandRow: {
                renderer: (row, rowIndex) => (
                    <div>
                        <Button variant="secondary" style={{marginRight: 10}} onClick={this.handleEditModalShow}
                                id={rowIndex} value={row.code} >Edit Supplier</Button>
                        <Button variant="danger" style={{marginRight: 10}} onClick={this.deleteSupplier} id={rowIndex}
                                value={row.code} >Delete Supplier</Button>
                    </div>
                ),
                showExpandColumn: false,
                onlyOneExpanding: true,
            },
            editModal: {
                code: "",
                modalShow: false,
                rowId: -1,
            },
            alertMsg: "",
            hasAlert: false,
            alertVariant: 'danger',
        }
    }

    componentDidMount(){
        SupplierService.getSupplier()
            .then((res) => {
                this.setState({ suppliers: res.data });
        }).catch((err) => {
            if (err.response === undefined) {
                this.setState({hasAlert: true, alertMsg: "Failed to connect to backend service", alertVariant: 'danger'})
            } else if (err.response.status === 404) {
                this.setState({hasAlert: true, alertMsg: "No suppliers found in database", alertVariant: 'info'})
            } else {
                this.setState({hasAlert: true, alertMsg: err.toString(), alertVariant: 'danger'})
            }
            console.log(err);
        });
    }

    deleteSupplier = (event) => {
        event.preventDefault();

        let code = event.target.value.toString();
        let rowIndex = event.target.id;

        SupplierService.deleteSupplier(code).then(res => {
            if (res.status === 200) {
                let updatedSuppliers = this.state.suppliers;
                updatedSuppliers.splice(rowIndex, 1);
                this.setState({products: updatedSuppliers});

                this.setState({
                    hasAlert: true,
                    alertMsg: "Successfully deleted supplier: " + code.toString(),
                    alertVariant: 'success'
                });
            } else if (res.status === 400) {
                this.setState({
                    hasAlert: true,
                    alertMsg: res.statusText,
                    alertVariant: 'danger'
                });
                console.log("Failure: bad request 400");
                this.history.push('/suppliers');
            } else {
                this.setState({
                    hasAlert: true,
                    alertMsg: res.statusText,
                    alertVariant: 'danger'
                })
                console.log("Unknown Error");
            }
        });
    }

    handleEditModalShow = (event) => {
        let code = event.target.value;
        let rowId = event.target.id;

        SupplierService.getSupplierByCode(code).then((res) => {
            this.setState({
                editModal: {
                    code: code,
                    supplier: res.data,
                    modalShow: true,
                },
            });
            console.log(JSON.stringify(this.state.editModal.supplier))
            //this.forceUpdate()
            console.log("Got info 1");
        }).catch((err) => {
            /*if (err.response.status === 404) {

            } else {

            }*/
            console.log(err);
        });
    };

    handleEditModalSubmit = (fromModal) => {
        let code = fromModal.code;
        let supplier = fromModal.supplier
        let address = fromModal.address;

        supplier = {
            code: code,
            address: address,
        }

        this.setState({
            editModal: {modalShow: false},
        });

        console.log('updated supplier => ' + JSON.stringify(supplier));

        SupplierService.updateSupplier(supplier, code).then(res => {
            if (res.status === 200) {
                //this.updateTableData();
                this.setState({hasAlert: true, alertMsg: "Successfully updated supplier", alertVariant: 'success'});
            } else if (res.status === 400) {
                console.log("Failure: bad request 400");
                this.setState({hasAlert: true, alertMsg: "Failed to update supplier!", alertVariant: 'danger'});
            } else {
                console.log("Unknown Error");
                this.setState({hasAlert: true, alertMsg: "Failed to update supplier!", alertVariant: 'danger'});
            }
        });
    };

    handleModalClose = () => {
        this.setState({
            editModal: {modalShow: false},
        });
    };

    dismissErrorHandler = (event) => {
        this.setState({hasAlert: false, alertMsg: ""});
    }

    render() {
        // noinspection JSValidateTypes
        return (
            <Container fluid style={{ marginTop: 50 }}>
                <BootstrapTable
                    bootstrap4
                    hover
                    condensed
                    keyField='code'
                    data={ this.state.suppliers }
                    columns={ this.state.columns }
                    expandRow={this.state.expandRow}
                    filter={filterFactory()}
                    noDataIndication="No suppliers found" />

                {this.state.editModal.modalShow &&
                <SupplierEditModal rowId={this.state.editModal.rowId} supplier={this.state.editModal.supplier}
                                  showHide={this.state.editModal.modalShow}
                                  onClick={this.handleEditModalSubmit} onHide={this.handleModalClose}/>
                }

                <Alert variant={this.state.alertVariant} show={this.state.hasAlert} onClose={this.dismissErrorHandler} style={{position: 'fixed', bottom: 70, left: 270, right: 20}}>{this.state.alertMsg}</Alert>
            </Container>
        );
    }
}

export default SupplierTable;