import React, {Component} from 'react'
import {Alert, Button, Form, Modal} from "react-bootstrap";

class SupplierEditModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isInvalid: false,
            invalidMsg: "",

            supplier: this.props.supplier,
            address: this.props.supplier.address,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressInputChange = this.handleAddressInputChange.bind(this);
    }

    componentDidMount() {

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            supplier: {
                [name]: value
            }
        });
    }

    handleAddressInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            address:{
                [name]: value
            },
        });
    }

    render() {
        return (
            <div>
                <Modal show={this.props.showHide} onHide={() => this.props.onHide({ msg: 'Cross Icon Clicked!' })}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Edit Supplier {this.props.supplier.code}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <label> Name: </label>
                                <Form.Control type="text" placeholder="Name" name="name"
                                              value={this.props.supplier.name}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Address Line One: </Form.Label>
                                <Form.Control type="text" placeholder="Address Line One" name="addressLineOne"
                                              value={this.state.address.addressLineOne} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Address Line Two: </Form.Label>
                                <Form.Control type="text" placeholder="Address Line Two" name="addressLineTwo"
                                              value={this.state.address.addressLineTwo} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> City: </Form.Label>
                                <Form.Control type="text" placeholder="City" name="city"
                                              value={this.state.address.city} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> County: </Form.Label>
                                <Form.Control type="text" placeholder="County" name="county"
                                              value={this.state.address.county} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Country: </Form.Label>
                                <Form.Control type="text" placeholder="Country" name="country"
                                              value={this.state.address.country} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> PostCode: </Form.Label>
                                <Form.Control type="text" placeholder="PostCode" name="postCode"
                                              value={this.state.address.postCode} onChange={this.handleAddressInputChange}/>
                            </Form.Group>
                        </Form>
                        <Alert variant="danger" show={this.state.isInvalid}>
                            {this.state.invalidMsg}
                        </Alert>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.onHide()} >Close</Button>
                        <Button variant="primary" onClick={() => this.props.onClick({ code: this.props.supplier.code, supplier: this.state.supplier, address: this.state.address})}
                                disabled={this.state.isInvalid}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    };
}

export default SupplierEditModal;