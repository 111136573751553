// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TransactionType = {
  "IN": "IN",
  "OUT": "OUT",
  "ADJUST": "ADJUST"
};

const { OrderLineItem, Order, Asset, Warehouse, Location, Invoice, Transaction, Contact, Supplier, Product, AssetProduct } = initSchema(schema);

export {
  OrderLineItem,
  Order,
  Asset,
  Warehouse,
  Location,
  Invoice,
  Transaction,
  Contact,
  Supplier,
  Product,
  AssetProduct,
  TransactionType
};