import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import "./SupplierBottomSpan.css";

class ProductBottomSpan extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="navbar">
                <a href={"/suppliers/create-supplier"} className="active">
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add Supplier
                </a>
            </div>
        );
    }
}

export default ProductBottomSpan;