import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import "./ProductBottomSpan.css";

function ProductBottomSpan(props) {
    return (
        <div className="navbar">
            <a href={"/products/create-product"} className="active">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Product
            </a>
        </div>
    );
}

export default ProductBottomSpan;