import axios from 'axios'
import authHeader from "../helpers/AuthHeader";

const PRODUCTS_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/products';

class ProductService {

    getProducts() {
        return axios.get(PRODUCTS_REST_API_URL, {
                headers: authHeader(),
                responseType: 'json', //Default anyway
                transformResponse: [function (data) {
                    let dataTransformed = data;

                    console.log(dataTransformed);
                    data.forEach(
                        function (cur, index) {
                            let dateTimeSplit = cur.createdDate.split("T");
                            console.log(dateTimeSplit);
                            dataTransformed[index]["createdDate"] = dateTimeSplit[0];
                        }
                    )

                    /*for (let val of data) {
                        let dateTimeSplit = val["createdDate"].split("T");
                        console.log(dateTimeSplit);
                        dataTransformed[x]["createdDate"] = dateTimeSplit[0];
                        x++;
                    }*/

                    return dataTransformed;
                }],
            }
        );
    }

    createProduct(product) {
        return axios.post(PRODUCTS_REST_API_URL, product, /*{headers: authHeader()}*/);
    }

    getProductById(productId) {
        return axios.get(PRODUCTS_REST_API_URL + '/' + productId, /*{headers: authHeader()}*/);
    }

    updateProduct(product, productId) {
        return axios.patch(PRODUCTS_REST_API_URL + '/' + productId, product, /*{headers: authHeader()}*/);
    }

    deleteProduct(productId) {
        return axios.delete(PRODUCTS_REST_API_URL + '/' + productId, /*{headers: authHeader()}*/)
    }
}

export default new ProductService()
