import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SupplierTable from "./SupplierTable";
import SupplierBottomSpan from "./SupplierBottomSpan";

class SupplierHome extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <>
                <SupplierTable />
                <SupplierBottomSpan/>
            </>
        );
    }
}

export default SupplierHome;