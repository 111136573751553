import axios from 'axios'

const SUPPLIERS_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/suppliers';

class SupplierService {

    getSupplier(){
        return axios.get(SUPPLIERS_REST_API_URL, {
           /* headers: authHeader(),*/
            responseType: 'json', //Default anyway
            transformResponse: [function (data) {
                //Add address string to response before it gets returned to table
                let dataTransformed = data;
                let x = 0;

                for (let val of data) {
                    dataTransformed[x]["addressStr"] = val["address"]["addressLineOne"] + ", " + val["address"]["addressLineTwo"] + ", " +
                        val["address"]["city"] + ", " + val["address"]["county"] + ", " + val["address"]["country"] + ", " + val["address"]["postCode"];
                    x++;
                }

                return data;
            }],
        });
    }

    createSupplier(supplier){
        return axios.post(SUPPLIERS_REST_API_URL, supplier, /*{headers: authHeader()}*/);
    }

    getSupplierByCode(supplierCode){
        return axios.get(SUPPLIERS_REST_API_URL + '/' + supplierCode, /*{headers: authHeader()}*/);
    }

    updateSupplier(supplier, supplierCode){
        return axios.put(SUPPLIERS_REST_API_URL + '/' + supplierCode, supplier, /*{headers: authHeader()}*/);
    }

    deleteSupplier(supplierCode) {
        return axios.delete(SUPPLIERS_REST_API_URL + '/' + supplierCode, /*{headers: authHeader()}*/)
    }
}

export default new SupplierService()
