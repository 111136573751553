import axios from 'axios'

const TRANSACTIONS_REST_API_URL = 'https://inv-mngr-api.danieldalton.tech' + '/transactions';

class TransactionService {

    getTransactions() {
        return axios.get(TRANSACTIONS_REST_API_URL, {
                /*headers: authHeader(),*/
                responseType: 'json', //Default anyway
                transformResponse: [function (data) {
                    let dataTransformed = data;
                    let x = 0;

                    for (let val of data) {
                        let dateTimeSplit = val["createdDate"].split("T");

                        dataTransformed[x]["createdDate"] = dateTimeSplit[0];

                        x++;
                    }

                    return dataTransformed;
                }],
            }
        );
    }

    createTransaction(transaction) {
        return axios.post(TRANSACTIONS_REST_API_URL, transaction, /*{headers: authHeader()}*/);
    }

    getTransactionById(transactionId) {
        return axios.get(TRANSACTIONS_REST_API_URL + '/' + transactionId, /*{headers: authHeader()}*/);
    }

    deleteTransaction(transactionId) {
        return axios.delete(TRANSACTIONS_REST_API_URL + '/' + transactionId, /*{headers: authHeader()}*/)
    }
}

export default new TransactionService()
