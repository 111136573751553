import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import TransactionService from "../../services/TransactionService";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {Alert, Button, Container} from "react-bootstrap";

class TransactionTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transactions: [],
            columns: [
                {
                    dataField: 'transactionId',
                    text: 'Transaction Number',
                    filter: textFilter(), // apply text filter
                    sort: true
                },
                {
                    dataField: 'partNumber',
                    text: 'Part Number',
                    filter: textFilter(), // apply text filter
                },
                {
                    dataField: 'transactionType',
                    text: 'Type',
                    filter: textFilter(), // apply text filter
                },
                {
                    dataField: 'quantity',
                    text: 'Quantity',
                    sort: true
                },
                {
                    dataField: 'createdDate',
                    text: 'Transaction Date',
                    sort: true
                },
            ],
            expandRow: {
                onlyOneExpanding: true,
                renderer: row => (
                    <div>
                        <Button variant="danger" style={{marginRight: 10}} onClick={this.deleteTransaction} value={row.transactionId}>Delete Transaction</Button>
                    </div>
                ),
                showExpandColumn: false
            },
            expanded: null,
            currentTransactionId: null,
            alertMsg: "",
            hasAlert: false,
            alertVariant: 'danger',
        }
    }

    componentDidMount(){
        this.updateTable()
    }

    updateTable(){
        TransactionService.getTransactions()
            .then((res) => {
                // Success
                this.setState({transactions: res.data});
            }).catch((err) => {
            if (err.response.status === 404){
                this.setState({hasAlert: true, alertMsg: "No transactions found in database", alertVariant: 'info'})
            } else {
                this.setState({hasAlert: true, alertMsg: err.toString(), alertVariant: 'danger'})
            }
            console.log(err);
        });
    }

    deleteTransaction = (event) => {
        event.preventDefault();

        let transactionId = event.target.value.toString();

        TransactionService.deleteTransaction(transactionId).then(res =>{
            if(res.status === 200){
                //this.props.history.push('/products');
                this.updateTable();

                this.setState({hasAlert: true, alertMsg: "Successfully deleted transaction", alertVariant: 'success'})
                console.log("Successfully deleted transaction");
            } else if(res.status === 400) {
                console.log("Failure: bad request 400");
                this.setState({hasAlert: true, alertMsg: res.statusText.toString(), alertVariant: 'danger'});
                //this.props.history.push('/products');
            } else {
                this.setState({hasAlert: true, alertMsg: res.statusText.toString(), alertVariant: 'danger'});
                console.log("Unknown Error");
            }
        });
    }

    dismissErrorHandler = (event) => {
        this.setState({hasAlert: false, alertMsg: ""});
    }

    render() {
        return (
            <Container fluid style={{ marginTop: 50, paddingBottom: 75 }}>
                <BootstrapTable
                    bootstrap4
                    hover
                    condensed
                    keyField='transactionId'
                    data={ this.state.transactions }
                    columns={ this.state.columns }
                    expandRow={this.state.expandRow}
                    filter={filterFactory()}
                    noDataIndication="No transactions found" />
                <Alert variant={this.state.alertVariant} dismissible='true' show={this.state.hasAlert} onClose={this.dismissErrorHandler} style={{position: 'fixed', bottom: 5, left: 270, right: 20}}>{this.state.alertMsg}</Alert>
            </Container>
        );
    }
}

export default TransactionTable;