import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ProductService from "../../services/ProductService";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {Alert, Button, Container} from "react-bootstrap";
import ProductCheckoutModal from "./ProductCheckoutModal";
import TransactionService from "../../services/TransactionService";
import ProductEditModal from "./ProductEditModal";
import ProductStockAdjustModal from "./ProductStockAdjustModal";

import { DataStore } from 'aws-amplify';
import { Product } from "../../models";

class ProductTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [],
            columns: [
                {
                    dataField: 'partNumber',
                    text: 'Part Number',
                    filter: textFilter(), // apply text filter
                    sort: true
                },
                {
                    dataField: 'mfgPartNumber',
                    text: 'Mfg Part Number',
                    filter: textFilter(), // apply text filter
                },
                {
                    dataField: 'description',
                    text: 'Description',
                    filter: textFilter(), // apply text filter
                },
                {
                    dataField: 'minStock',
                    text: 'Minimum Desired Stock',
                    sort: true
                },
                {
                    dataField: 'currentStock',
                    text: 'Current Stock',
                    sort: true
                },
                {
                    dataField: 'costPrice',
                    text: 'Cost Price',
                    sort: true
                },
                {
                    dataField: 'createdDate',
                    text: 'Date Created',
                    sort: true
                },
            ],
            expandRow: {
                renderer: (row, rowIndex) => (
                    <div>
                        <h3>{`Part Number:  ${row.partNumber}`}</h3>
                        <p>{`Manufacturers Part Number:  ${row.mfgPartNumber}`}</p>
                        <p>{`Part Description:  ${row.description}`}</p>
                        <p>{`Cost Price:  ${row.costPrice}`}</p>
                        <p>{`Current Stock:  ${row.currentStock} | Min Stock :  ${row.minStock}`}</p>

                        <Button variant="secondary" style={{marginRight: 10}} onClick={this.handleCheckoutModalShow}
                                id={rowIndex} value={row.partNumber}>Checkout Product</Button>
                        <Button variant="secondary" style={{marginRight: 10}} onClick={this.handleStockAdjModalShow}
                                id={rowIndex} value={row.partNumber} disabled={this.checkRole(2)}>Stock Adjust</Button>
                        <Button variant="secondary" style={{marginRight: 10}} onClick={this.handleEditModalShow}
                                id={rowIndex} value={row.partNumber} disabled={this.checkRole(2)}>Edit Product</Button>
                        <Button variant="danger" style={{marginRight: 10}} onClick={this.deleteProduct} id={rowIndex}
                                value={row.partNumber} disabled={this.checkRole(2)}>Delete Product</Button>
                    </div>
                ),
                showExpandColumn: false,
                onlyOneExpanding: true,
            },
            checkout: {
                partNumber: "",
                modalShow: false,
                rowId: -1,
                currentStock: 0,
            },
            editModal: {
                partNumber: "",
                product: null,
                modalShow: false,
                rowId: -1,
            },
            stockAdjModal: {
                partNumber: "",
                product: null,
                modalShow: false,
                rowId: -1,
            },
            alertMsg: "",
            hasAlert: false,
            alertVariant: 'danger',
            currentRole: 3,
        }
    }

    componentDidMount() {
        this.fetchTableData();
    }

    async fetchTableData() {
        const products = await DataStore.query(Product);
        this.setState({products: products});
        
        // ProductService.getProducts().then((res) => {
        //     const products = res.data;
        //     console.log("P1")
        //     console.log(products);
        //     this.setState({products: products});
        // }).catch((err) => {
        //     if (err.response === undefined) {
        //         this.setState({hasAlert: true, alertMsg: "Failed to connect to backend service", alertVariant: 'danger'})
        //     } else if (err.response.status === 404) {
        //         this.setState({hasAlert: true, alertMsg: "No products found in database", alertVariant: 'info'})
        //     } else {
        //         this.setState({hasAlert: true, alertMsg: err.toString(), alertVariant: 'danger'})
        //     }
        //     console.log(err);
        // });

        this.setState({
            alertMsg: "",
            hasAlert: false,
            alertVariant: 'danger',
            checkout: {modalShow: false},
            editModal: {modalShow: false},
        });
    }

    checkRole(requiredRole) {
        return this.state.currentRole <= requiredRole;
    }

    deleteProduct = (event) => {
        event.preventDefault();

        let productId = event.target.value.toString();
        let rowIndex = event.target.id;
        console.log(rowIndex);

        ProductService.deleteProduct(productId).then(res => {
            if (res.status === 200) {
                let updatedProducts = this.state.products;
                updatedProducts.splice(rowIndex, 1);
                this.setState({products: updatedProducts});

                this.setState({
                    hasAlert: true,
                    alertMsg: "Successfully deleted product: " + productId.toString(),
                    alertVariant: 'success'
                });
            } else if (res.status === 400) {
                this.setState({
                    hasAlert: true,
                    alertMsg: res.statusText,
                    alertVariant: 'danger'
                });
                console.log("Failure: bad request 400");
                this.history.push('/products');
            } else {
                this.setState({
                    hasAlert: true,
                    alertMsg: res.statusText,
                    alertVariant: 'danger'
                })
                console.log("Unknown Error");
            }
        });
    }

    dismissErrorHandler = () => {
        this.setState({hasAlert: false, alertMsg: ""});
    }

    handleCheckoutModalShow = (event) => {
        let partNumber = event.target.value;
        let rowId = event.target.id;

        ProductService.getProductById(partNumber).then((res) => {
            this.setState({
                checkout: {
                    currentProduct: res.data,
                    currentStock: res.data.currentStock,
                    partNumber: partNumber,
                    rowId: rowId,
                    modalShow: true,
                }
            });
        }).catch((err) => {
            if (err.response.status === 404) {
                this.setState({hasAlert: true, alertMsg: "No product found in database", alertVariant: 'info'})
            } else {
                this.setState({hasAlert: true, alertMsg: err.toString(), alertVariant: 'danger'})
            }
            console.log(err);
        });
    };

    handleCheckoutModalSubmit = (fromModal) => {
        let partNumber = fromModal.partNumber;
        let quantity = fromModal.quantity;
        let oldStock = fromModal.oldStock;

        this.setState({
            checkout: {modalShow: false},
        });

        let transaction = {partNumber: partNumber, transactionType: 'out', quantity: parseInt(quantity)};

        TransactionService.createTransaction(transaction).then(res => {
            if (res.status === 201) {
                console.log("Successfully created transaction");

                let currentStock = oldStock - quantity;
                let product = {currentStock: currentStock}
                ProductService.updateProduct(product, partNumber).then(res => {
                    if (res.status === 200) {
                        console.log("Successfully updated product");
                        this.fetchTableData();
                        this.setState({
                            hasAlert: true,
                            alertMsg: "Successfully checked out product",
                            alertVariant: 'success'
                        });
                    } else if (res.status === 400) {
                        console.log("Failed to update products current stock");
                        this.setState({
                            hasAlert: true,
                            alertMsg: "Failed to update product stock!",
                            alertVariant: 'danger'
                        });
                    } else {
                        console.log("Unknown Error");
                        this.setState({
                            hasAlert: true,
                            alertMsg: "Failed to update product stock",
                            alertVariant: 'danger'
                        });
                    }
                });

            } else if (res.status === 400) {
                console.log("Failure: bad request 400");
                this.setState({
                    hasAlert: true,
                    alertMsg: "Failed to create transaction product!",
                    alertVariant: 'danger'
                });
            } else {
                console.log("Unknown Error");
                this.setState({
                    hasAlert: true,
                    alertMsg: "Failed to create transaction product!",
                    alertVariant: 'danger'
                });
            }
        });
    };

    handleModalClose = () => {
        this.setState({
            checkout: {modalShow: false},
            editModal: {modalShow: false},
            stockAdjModal: {modalShow: false},
        });
    };

    handleEditModalShow = (event) => {
        let partNumber = event.target.value;
        let rowId = event.target.id;

        ProductService.getProductById(partNumber).then((res) => {
            this.setState({
                editModal: {
                    partNumber: partNumber,
                    product: res.data,
                    modalShow: true,
                },
            });
            //this.forceUpdate()
            console.log("Got info 1");
        }).catch((err) => {
            /*if (err.response.status === 404) {

            } else {

            }*/
            console.log(err);
        });
    };

    handleStockAdjModalShow = (event) => {
        let partNumber = event.target.value;
        let rowId = event.target.id;

        ProductService.getProductById(partNumber).then((res) => {
            this.setState({
                stockAdjModal: {
                    partNumber: partNumber,
                    product: res.data,
                    modalShow: true,
                },
            });
            //this.forceUpdate()
            console.log("Got info 1");
        }).catch((err) => {
            /*if (err.response.status === 404) {

            } else {

            }*/
            console.log(err);
        });
    };

    handleEditModalSubmit = (fromModal) => {
        let partNumber = fromModal.partNumber;
        let product = fromModal.product;

        this.setState({
            editModal: {modalShow: false},
            stockAdjModal: {modalShow: false},
        });

        console.log('updated product => ' + JSON.stringify(product));

        ProductService.updateProduct(product, partNumber).then(res => {
            if (res.status === 200) {
                this.fetchTableData();
                this.setState({hasAlert: true, alertMsg: "Successfully updated product", alertVariant: 'success'});
            } else if (res.status === 400) {
                console.log("Failure: bad request 400");
                this.setState({hasAlert: true, alertMsg: "Failed to update product!", alertVariant: 'danger'});
            } else {
                console.log("Unknown Error");
                this.setState({hasAlert: true, alertMsg: "Failed to update product!", alertVariant: 'danger'});
            }
        });
    };

    handleStockAdjModalSubmit = (fromModal) => {
        let partNumber = fromModal.partNumber;
        let product = fromModal.product;

        this.setState({
            editModal: {modalShow: false},
            stockAdjModal: {modalShow: false},
        });

        console.log('updated product => ' + JSON.stringify(product));

        ProductService.updateProduct(product, partNumber).then(res => {
            if (res.status === 200) {
                this.fetchTableData();
                this.setState({hasAlert: true, alertMsg: "Successfully updated product", alertVariant: 'success'});
            } else if (res.status === 400) {
                console.log("Failure: bad request 400");
                this.setState({hasAlert: true, alertMsg: "Failed to update product!", alertVariant: 'danger'});
            } else {
                console.log("Unknown Error");
                this.setState({hasAlert: true, alertMsg: "Failed to update product!", alertVariant: 'danger'});
            }
        });
    };

    render() {
        return (
            <Container fluid style={{marginTop: 50, paddingBottom: 75}}>
                <BootstrapTable
                    bootstrap4
                    hover
                    condensed
                    keyField='partNumber'
                    data={this.state.products}
                    columns={this.state.columns}
                    expandRow={this.state.expandRow}
                    filter={filterFactory()}
                    noDataIndication="No products found"/>

                {this.state.editModal.modalShow &&
                <ProductEditModal rowId={this.state.editModal.rowId} product={this.state.editModal.product}
                                  showHide={this.state.editModal.modalShow}
                                  onClick={this.handleEditModalSubmit} onHide={this.handleModalClose}/>
                }

                {this.state.stockAdjModal.modalShow &&
                <ProductStockAdjustModal rowId={this.state.stockAdjModal.rowId} product={this.state.stockAdjModal.product}
                                  showHide={this.state.stockAdjModal.modalShow}
                                  onClick={this.handleEditModalSubmit} onHide={this.handleModalClose}/>
                }

                <ProductCheckoutModal rowId={this.state.checkout.rowId} partNumber={this.state.checkout.partNumber}
                                      currentStock={this.state.checkout.currentStock}
                                      showHide={this.state.checkout.modalShow} onClick={this.handleCheckoutModalSubmit}
                                      onHide={this.handleModalClose}/>

                <Alert variant={this.state.alertVariant} dismissible='true' show={this.state.hasAlert}
                       onClose={this.dismissErrorHandler}
                       style={{position: 'fixed', bottom: 70, left: 270, right: 20}}>{this.state.alertMsg}</Alert>
                       
            

            </Container>
        );
    }
}

export default ProductTable;