import React from 'react';

function SidebarUserInfo(props) {
    /*const [username, setUsername] = useState(null);
    const [user, setUser] = useState({});

    useEffect(() => {
        setUsername(AuthService.getUsername());

        if (username) {
            UserService.getUserInfo(username).then(res => {
                setUser(res.data);
            });
        }
    }, [username]);*/

    return (
        <div>
            <p className="ml-3" style={{marginBottom: "5px"}}>{props.username}</p>
            {/* <p className="ml-3">Role: {user.role}</p>*/}
        </div>
    );
}

export default SidebarUserInfo;