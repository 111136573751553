import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ProductHome from "./views/Products";
import SupplierHome from "./components/suppliers/SupplierHome";
import DashboardHome from "./views/Dashboard";
import ProductCreate from "./components/products/ProductCreate";
import SideBar from "./components/sidebar/Sidebar";
import {Container} from "react-bootstrap";

import "./App.css";
import SupplierCreate from "./components/suppliers/SupplierCreate";
import {Redirect} from "react-router";
import TransactionHome from "./components/transactions/TransactionHome";

import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


function App(props) {
    return (
            <Authenticator>
                {({signOut, user}) => (
                    /* <main>
                         <h1>Hello {user.username}</h1>
                         <button onClick={signOut}>Sign out</button>
                     </main>*/
                    <div className="App wrapper">
                        <SideBar user={user} signOut={signOut}/>
                        <Router>
                            <div className="content is-open container-fluid">
                                <Switch>
                                    {/*<Route path="/" exact component={DashboardHome}/>*/}
                                    <Route path="/" exact
                                           render={(props) => <DashboardHome {...props} user={user} signOut={signOut}/>}/>
    
    
                                    <Route path="/products" exact component={ProductHome}/>
                                    <Route path="/products/create-product" exact component={ProductCreate}/>
    
                                    <Route path="/suppliers" exact component={SupplierHome}/>
                                    <Route path="/suppliers/create-supplier" exact component={SupplierCreate}/>
    
                                    <Route path="/transactions" exact component={TransactionHome}/>
    
                                    {/*Redirect all other urls to / (when logged in)*/}
                                    <Route>
                                        <Redirect to="/"/>
                                    </Route>
                                </Switch>
                            </div>
                        </Router>
                    </div>
                )}
            </Authenticator>
    );
}

export default App;
