import React from "react";
import {
    faBox,
    faClipboardList,
    faExchangeAlt,
    faFileInvoice,
    faHome,
    faSignOutAlt,
    faTruckLoading,
    faUsers,
    faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Nav} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import SidebarItem from "./SidebarItem";
import SidebarHeader from "./SidebarHeader";
import SidebarUserInfo from "./SidebarUserInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SideBar extends React.Component {
    constructor(props) {
        super(props)

        this.handleLogout = this.handleLogout.bind(this)
    }

    isShown() {
        let location = window.location.pathname;
        console.log(location)
        if (location === "/login") {
            return "";
        } else {
            return "is-open";
        }
    }

    handleLogout() {
        AuthService.logout();
    }

    render() {
        return (
            <div className={"sidebar " + this.isShown()}>
                <SidebarHeader/>
                <Nav className="flex-column pt-2" style={{position: 'sticky', top: '250px'}}>

                    {/*TODO: Extract to component*/}
                    <SidebarUserInfo username={this.props.user.username}/>

                    <SidebarItem text="Home" href="/" icon={faHome}/>
                    <SidebarItem text="Products" href="/products" icon={faBox}/>
                    <SidebarItem text="Suppliers" href="/suppliers" icon={faTruckLoading}/>
                    <SidebarItem text="Transactions" href="/transactions" icon={faExchangeAlt}/>
                    <SidebarItem text="Storerooms" href="/storerooms" icon={faWarehouse} disabled={true}/>
                    <SidebarItem text="Invoices" href="/invoices" icon={faFileInvoice} disabled={true}/>
                    <SidebarItem text="Orders" href="/Orders" icon={faClipboardList} disabled={true}/>
                    <SidebarItem text="Users" href="/users" icon={faUsers} disabled={true}/>
                    <SidebarItem text="Logout" href="/" icon={faSignOutAlt} onSelect={this.props.signOut}/>

                    <Container>
                        <Button onClick={this.props.signOut}>
                            <FontAwesomeIcon icon="fa-regular fa-right-from-bracket"/>
                            Sign Out
                        </Button>
                    </Container>
                </Nav>
            </div>
        );
    }
}

export default SideBar;